body {
  overflow: scroll;
  overflow-wrap: break-word;
  flex-wrap: wrap;
  /* background-color:  #f3efea; */
  background-color: #E6F4F1;
  font-family: 'Poppins', sans-serif;
}

h1, h2 {
  font-weight: 400;
}

html {
  scroll-behavior: smooth;
  overflow: scroll;
  overflow-wrap: break-word;
  flex-wrap: wrap;
}

.centerNav{
    display: inline-flex;
    flex-direction: horizontal;
    margin-left: auto;
    align-items: center;
}

.mainBody {
  padding: 0px 2vw;
}

.carouselItemMain {
  padding: 0.5rem;
}

.header {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 2px;
  background-color: white;
  padding: 10px;
  margin: 0px 2vw;
  margin-top: 2vh;
  border-radius: 6px;
  background-color: #fefcfa;
  border-image:   linear-gradient(to right, grey 25%, yellow 25%, yellow 50%,red 50%, red 75%, teal 75%) 5;
}

.carousel-inner {
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.raise {
  transition: all 0.2s ease-in-out;
  margin: 0 5px;
  font-weight: 500;
}

.raise:hover,
.raise:focus {
  box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
  transform: translateY(-0.25em);
  color: #f8a162;
}

.btn-outline-primary {
  border-radius: 5px;
  color: #fbac73;
  border-color: #fbac73;;
  transition: all 0.2s ease-in-out;
  font-weight: 500;
}

.btn-outline-primary:hover {
  background-color: #f8a162;
  color: white;
  font-weight: 500;
  border-color: #fbac73;;
}

/* footer {
    background: #282834;
    color: #b6b7b9;
    padding: 10% 0 5% 0;
  }
  
  footer .grid {
    grid-template-columns: 6fr 3fr 3fr;
  }
  
  footer p {
    color: #b6b7b9;
    font-size: 15px;
    line-height: 25px;
  }
  
  footer .icon i {
    margin: 20px 20px 20px 0;
    color: #b6b7b9;
  }
  
  footer h2 {
    color: #fff;
    margin-bottom: 10px;
  }
  
  footer li {
    margin-bottom: 20px;
  }
  
  footer i {
    color: #7fc142;
    margin: 20px 0;
    margin-right: 20px;
  }
  
  footer label {
    margin: 20px 0;
  } */

  .logo_image {
   width:100vw; /* Matches to the Viewport Width */
   height:auto;
   max-width:100% !important;
  }

  .footer_row{
    background: #282834;
    color: #b6b7b9;
    padding: 10% 0 5% 0;
  }

  .footer_row > .icon{
    margin: 20px 20px 20px 0;
    color: #fff;
  }
/* footer{
    padding-bottom: 50;
} */

/* .about > .heading > h1 {
    top: 55px;
  }
  
  .about {
    position: relative;
  }
  
  .about > .left {
    margin-top: 30px;
  }
  
  .about > p{
    font-size: 15px;
  }
  
  .about > button {
    margin: 30px 0;
  } */

  .brandLogo {
    width: fit-content;
    height: 6vh;
    object-fit: cover;
  }

  .img-box {
    padding: 15px;
    border-radius: 5px;
  }
  .img-box img {
    border: 10px solid transparent;
    width: 100%;
    border-radius: 5px;
  }
  .inner-shadow {
    box-shadow: var(--inner-shadow);
  }
  
  .maininfo_aboutme {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 5vh 2vw;
    background-color: #faf7f4;
    /* margin-top: 500px;
    margin-bottom: 500px; */
  }

  /* .maininfo_aboutme > div:nth-child(1) {
    text-align: left;
  }

  .maininfo_aboutme > div:nth-child(2) {
    text-align: right;
  }

  .maininfo_aboutme > div > div{
    flex: 0.5;
    flex-wrap: wrap;
  } */
/* 
  .maininfo_aboutme > div:nth-child(1) > div:nth-child(2) {
    text-align: left;
  }

  .maininfo_aboutme > div:nth-child(2) > div:nth-child(1) {
    text-align: left;
  }

   */



/* Footer */
  section {
      padding: 60px 0;
      min-height: 100vh;
  }
  a, a:hover, a:focus, a:active {
      text-decoration: none;
      outline: none;
  }
  ul {
      margin: 0;
      padding: 0;
      list-style: none;
  }
  
  .main-footer{
    position: relative;
    background: #1e2129;
    margin-top: 2vh;
  }
  
  .footer-content{
    position: relative;
    padding: 80px 0px 80px 0px;
    color: white;
  }
  .footer-content:before{
    position: absolute;
    content: '';
    background: url(https://i.ibb.co/jyRLrBZ/world-map.png);
    width: 50vw;
    height: 50vh;
    top: 0px;
    right: 0px;
    background-size: cover;
    background-repeat: no-repeat;
    animation-name: float-bob;
    animation-duration: 30s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -webkit-animation-name: float-bob;
    -webkit-animation-duration: 30s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: float-bob;
    -moz-animation-duration: 30s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: float-bob;
    -ms-animation-duration: 30s;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    -o-animation-name: float-bob;
    -o-animation-duration: 30s;
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear;
  }
  .footer-content .logo-widget{
    position: relative;
    margin-top: -5px;
  }
  .footer-content .logo-widget > div:nth-child(1) {
    background: rgb(35, 117, 241);
    /* font-family: serif; */
    background: linear-gradient(green 0%, lightgray 25%, yellow 50%, orange 75%, gray 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .footer-content .logo-widget .footer-social li{
    position: relative;
    display: inline-block;
    margin-right: 9px;
  }
  .footer-content .logo-widget .footer-social li:last-child{
    margin-right: 0px;
  }
  .footer-content .logo-widget .footer-social li a{
    position: relative;
    display: inline-block;
    width: 42px;
    height: 42px;
    line-height: 42px;
    background: #2e3138;
    color: #9ea0a9;
    text-align: center;
    border-radius: 50%;
    transition: all 0.3s ease-in-out;
  }
  .footer-content .logo-widget .footer-social li a > svg{
    height: 3vh;
  }
  .footer-content .logo-widget .footer-social li a:hover{
    color: #ffffff;
    background: white;
  }
  .footer-content .logo-widget .logo-box{
    margin-bottom: 25px;
  }
  .footer-content .logo-widget .text p{
    color: #9ea0a9;
    margin-bottom: 32px;
  }
  .footer-content .footer-title{
    position: relative;
    font-size: 24px;
    line-height: 35px;
    /* font-family: 'Playfair Display', serif; */
    color: #ffffff;
    font-weight: 700;
    margin-bottom: 27px;
  }
  .footer-content .service-widget .list li{
    display: block;
    margin-bottom: 12px;
  }
  .footer-content .service-widget .list li a{
    position: relative;
    display: inline-block;
    color: #9ea0a9;
  }
  .footer-content .service-widget .list li a:hover{
    color: white
  }
  .footer-content .contact-widget .text p{
    position: relative;
    display: inline-block;
    color: #9ea0a9;
  }
  .footer-content .contact-widget p{
    color: #9ea0a9;
    margin-bottom: 15px;
  }
  /* .footer-content .contact-widget{
    margin-left: 90px;
  } */
  .footer-content .contact-widget .footer-title{
    margin-bottom: 29px;
  }
  
  .headingbg {
    position: absolute;
    font-size: 80px;
    opacity: 0.1;
    margin-top: -25px;
  }

  .heading > h2 {
    font-size: 40px;
    /* font-family: serif; */
    font-weight: 500;
  }

  .slides {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .slides > div:nth-child(2) {
    display: flex;
    background-color: #FAF7F3;
  }

  .slides > div:nth-child(2) > div {
    margin: 0px 10px;
  }

  .slides > div:nth-child(2) > div {
    max-width: 81vw;
  }

  .slides > div:nth-child(2) > div > div:nth-child(2) > div{
    height: fit-content;
  }

  .mainCard {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    width: 80%;
    height: 55vh;
    max-height: fit-content;
    margin: 70px 40px;
    display: flex;
    flex-direction: column;
    background-color: #FAF7F3;
    transition: all 0.3s ease-in-out;
  }

  .mainCard:hover {
    transform: scale(1.05);
  }

  .card {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    flex: 0.1;
  }

  .card > .card-body > .card-title {
    font-weight: bold;
  }

  .card_button {
    border-radius: 3px;
  }

  .cardBody {
    margin-top: 50px;
    /* background-color: red; */
    flex: 0.9;
    display: flex;
    flex-direction: column;
  }

  .cardBody > div:nth-child(1) {
    font-weight: bold;
    font-size: xx-large;
    /* font-family: serif; */
    line-height: 1;
    flex: 0.4;
  }
  .cardBody > div:nth-child(2) {
    font-weight: bold;
    line-height: 1;
    text-align: center;
    flex: 0.1;
  }
  .cardBody > div:nth-child(3) {
    line-height: 1;
    text-align: center;
    flex: 0.3;
  }
  .cardBody > div:nth-child(4) {
    flex: 0.2;
  }
  .cardBody > div:nth-child(4) > button{
    line-height: 1;
    text-align: center;
    border-radius: 3px;
    padding: 10px;
    background-color: rgb(35, 117, 241);
  }
  




  .card {
    width: var(--card-width);
    /* height: var(--card-height); */
    height: 100px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 0 30px;
    perspective: 2500px;
    margin: 5px 40px;
    z-index: 999;
  }
  
  .cover-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .wrapper {
    transition: all 0.5s;
    position: absolute;
    width: 100%;
    z-index: -1;
  }
  
  .card:hover .wrapper {
    transform: perspective(900px) translateY(-2%) rotateX(20deg) translateZ(0);
    box-shadow: 2px 35px 32px -8px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 2px 35px 32px -8px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 2px 35px 32px -8px rgba(0, 0, 0, 0.75);
  }
  
  .wrapper::before,
  .wrapper::after {
    content: "";
    opacity: 0;
    width: 100%;
    height: 10vh;
    transition: all 0.5s;
    position: absolute;
    left: 0;
  }
  .wrapper::before {
    top: 0;
    height: 100%;
    background-image: linear-gradient(
      to top,
      transparent 46%,
      rgba(12, 13, 19, 0.5) 68%,
      rgba(12, 13, 19) 97%
    );
  }
  .wrapper::after {
    bottom: 0;
    opacity: 1;
    background-image: linear-gradient(
      to bottom,
      transparent 46%,
      rgba(12, 13, 19, 0.5) 68%,
      rgba(12, 13, 19) 97%
    );
  }
  
  .card:hover .wrapper::before,
  .wrapper::after {
    opacity: 1;
  }
  
  .card:hover .wrapper::after {
    height: 120px;
  }
  .title {
    width: 100%;
    transition: transform 0.5s;
  }
  .card:hover .title {
    transform: translate3d(0%, -50px, 100px);
  }
  
  .character {
    width: 100%;
    opacity: 0;
    transition: all 0.5s;
    position: absolute;
    z-index: -1;
    object-fit: cover;
  }
  
  .card:hover .character {
    opacity: 1;
    transform: translate3d(0%, -30%, 100px);
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .modal-body > div:nth-child(1) {
    margin: 20px 0px;
  }

  .modal-body > div:nth-child(2) {
    flex: 0.3;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f8f6f3;
  }

  .carousel-hotel {
    width: 40vw;
    height: 40vh;
  }

  .modal-body > div:nth-child(3) {
    flex: 0.7;
    margin: 40px 20px;
    padding: 20px;
    background-color: #fcfbfa;
  }

  .modal-body > div:nth-child(3) > div:nth-child(1){
    /* font-family: serif; */
    font-size: large;
    font-weight: 600;
  }

  .modal-body > div:nth-child(3) > div:nth-child(2){
    /* font-family: serif; */
    font-size: large;
    margin-top: 20px;
  }

  .modal-body > div:nth-child(3) > div:nth-child(2) > a > button{
    border-radius: 3px;
    background-color: #2374F1;
  }

  .hotelimage {
    width: 40vw;
    height: 40vh;
    object-fit: cover;
  }

  .offers {
    /* padding: 5rem; */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .offersCard {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    margin: 2rem;
    height: 50vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
  }
  .offersCard > div:nth-child(1) {
    flex: 0.25;
  }
  .offersCard > div:nth-child(1) > img {
    object-fit: contain;
    width: 80%;
  }
  .offersCard > div:nth-child(2) {
    flex: 0.25;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 600;
  }
  .offersCard > div:nth-child(3) {
    flex: 0.25;
  }
  .offersCard > div:nth-child(4) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 0.25;
  }
  /* .offers > div:nth-child(2) {
    background-color: #fcfbfa;
    padding: 20px 10px;
  }

  .offers > div:nth-child(2) > div {
    transition: all 0.3s ease-in-out;
  }

  .offers > div:nth-child(2) > div:hover{
    transform: scale(1.1);
  } */

  .nav-link > a{
    color: rgba(0, 0, 0, 0.65);
    transition: all 0.2s ease-in-out;
  }

  .nav-link > a:hover {
    color: #f8a162;
  }

  input, textarea {
    outline: none;
    padding: 10px;
  }

  .modal-header {
    /* font-family: serif; */
    font-size: larger;
  }

  .modal-body {
    /* font-family: serif; */
  }

  .modal-footer > button {
    border-radius: 3px;
    background-color: #2374F1;
    /* font-family: serif; */
    color: white;
    border: none;
    padding: 5px 10px;
  }

  /* Testimonial */
  .container-testimonial {
    margin: 5vh 8vw;
    color: white;
    background-image: url("../images/background-2.png");
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 5px;
  }

  .testimonial-carousel-item {
    padding: 3vh 5vw;
  }

  .testimonial-carousel-item > h4 {
    color: #7FC141;
    font-weight: bold;
  }

  .testimonial-carousel-item > div {
    border-radius: 999px;
  }

  .testimonial-carousel-item > div > img {
    height: 22vh;
    width: 12vw;
    object-fit: cover;
    border-radius: 999px;
  }

  .testimonial-carousel-item > p {
    font-weight: bold;
    font-size: larger;
  }

  .hotelCarousal{
    background-color: #000000;
  }